import config from 'appConfig';
import { call, put, select } from 'redux-saga/effects';
import {
  addNewDeliveryAddressApi,
  getDeliverySlotsApi,
  getOrderConfirmationApi,
  postDeliveryAddressApi,
  postDeliverySlotsApi,
  removeShoppingCartApi,
  subscribeNewUserApi,
  updateDeliveryAddressApi,
  validateSelectedLocationForNudgeApi,
  validateUserLocationApi
} from './checkout.api';
import history from '../../history';
import { launchInfoSelector, SET_LAUNCH_INFO, SET_NOTIFICATION_MSG } from '../../initial.action';
import { RESET_CART_SKUS, SET_CART_QTY_COUNT } from '../cart/cart.action';
import { sendAnalytics } from '../../analytics';
import { selectInitialData } from '../../initial.selectors';
import { SET_CONFIRMATION_PAGE_MODAL, SET_NON_SERVICEABLE_MODAL, SET_SCRATCH_CARD_MODAL } from '../modal/modal.action';
import { SET_CHECKOUT_LOADING, SET_DELIVERY_SLOTS, SET_ORDER_CONFIRMATION } from './checkout.action';

const { trackGtmEvent = () => {} } = config;

export function* postDeliveryAddressSaga({data, redirectURL}) {
  try {
    const resp = yield call(postDeliveryAddressApi, data);
    if (!config.isApp) {
      history.push(redirectURL);
    } else {
      redirectURL(resp);
    }
  } catch (e) {
    console.log('Err @postDeliveryAddressSaga: ', e, e.message);
  }
}
export function* addNewDeliveryAddressSaga({data, redirectURL}) {
  try {
    const {data: resp} = yield call(addNewDeliveryAddressApi, data);
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...resp};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (!config.isApp) {
      history.replace(redirectURL);
    } else {
      redirectURL();
    }
  } catch (e) {
    console.log('Err @addNewDeliveryAddressSaga: ', e, e.message);
  }
}
export function* updateDeliveryAddressSaga({data, cb}) {
  try {
    const {data: resp} = yield call(updateDeliveryAddressApi, data);
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...resp};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (cb) {
      cb();
    }
  } catch (e) {
    console.log('Err @updateDeliveryAddressSaga: ', e, e.message);
  }
}
export function* subscribeNewUserSaga({data, cb}) {
  try {
    const {data: resp} = yield call(subscribeNewUserApi, data);
    if (cb) {
      cb();
    }
  } catch (e) {
    console.log('Err @updateDeliveryAddressSaga: ', e, e?.message);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message:
          e?.message ||
          e?.data?.description ||
          'Unable to subscribe the given email',
      },
    });
  }
}
export function* validateUserLocationSaga({data, cb}) {
  const payload = {
    address_line: data?.addressLine2,
    area: data?.area,
    city: data?.city,
    country: data?.country,
    latitude: data?.latitude,
    longitude: data?.longitude,
    map_location: data?.mapLocation,
    pin_code: data?.pinCode,
    primary_text: data?.primaryText,
    secondary_text: data?.secondaryText,
    state: data?.state
  }
  try {
    console.warn('validateUserLocationSaga', data);

    const {data: resp} = yield call(validateUserLocationApi, data);
    const address = resp.customerAddress || resp.visitorAddress;
    if (resp.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...address};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    // const address = resp.customerAddress || resp.visitorAddress;
    if (!config.isApp) {
      if (cb) {
        cb();
      } else {
        history.push('/');
      }
    } else {
      cb(resp.resetCart);
    }
    // Analytics
    const key = 'confirm_location'
    // Clevertap Analytics
    sendAnalytics({
      key: 'Confirm Location',
      payload: {...data},
      clevertapPriority: 1
    });

    // Firebase And Facebook Analytics
    sendAnalytics({
      key,
      payload,
      firebasePriority: 1,
      facebookPriority: 1
    });
  } catch (e) {
    const {launchInfo} = yield select(selectInitialData);
    // Analytics
    const key = 'out_of_service_area'
    // Clevertap Analytics
    sendAnalytics({
      key: 'Out Of Service Area',
      payload: {...data},
      clevertapPriority: 1,
    });

    // Firebase And Facebook Analytics
    sendAnalytics({
      key,
      payload,
      firebasePriority: 1,
      facebookPriority: 1
    });
    console.log('Err @validateUserLocationSaga in checkout: ', e);
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}
export function* getDeliverySlotsSaga() {
  try {
    // console.log('Getting delivery Slots data');
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const {data} = yield call(getDeliverySlotsApi);
    const showShipmentErrorMessage = !data?.shipments || !data?.shipments.length;
    yield put({type: SET_DELIVERY_SLOTS, data});
    if(showShipmentErrorMessage){
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: "Error while loading shipment set",
        },
      });
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  } catch (e) {
    console.log('Err @getDeliverySlotsSaga: ', e);
    let errorMsg = 'Error fetching shipments.';
    if (e.status === 401) {
      errorMsg = 'Unauthorised Access! Please login to access this service';
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}
export function* postDeliverySlotsSaga({data, redirect = null}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const deliverResp = yield call(postDeliverySlotsApi, data);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    if (!config.isApp) {
      let value = [],
        label = [];
      data.availableDeliverySlots.map(slot => {
        value.push(slot.deliverySlotId);
        label.push(
          `${slot.now ? 'NOW: ' : ''}${slot?.deliverySlotStartTime}: ${
            slot.slot
          }`,
        );
      });
      const gtmData = {
        eventName: 'addShippingInfo',
        eventData: {
          category: 'checkout > shipment',
          value: value.join(', '),
          label: label.join(', '),
        },
      };
      trackGtmEvent(gtmData);
      history.push(redirect);
    } else {
      redirect(deliverResp);
    }
  } catch (e) {
    console.log('Err @postDeliverySlotsSaga: ', e);
    let errorMsg = 'Error saving shipments.';
    if (e.status === 401) {
      errorMsg = 'Unauthorised Access! Please login to access this service';
    }
    if (e.data?.description) {
      errorMsg = e.data?.description;
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}
export function* getOrderConfirmationSaga({data}) {
  try {
    const {launchInfo} = yield select(selectInitialData);
    yield put({ type: SET_CHECKOUT_LOADING, data: true });
    const { data: orderResp } = yield call(getOrderConfirmationApi, data);
    const {orderDetails} = orderResp
    const {cartItems=[]} = orderDetails || {}
    if(orderResp?.scratchCard){
      const payload = {
        visible: true,
        source: 'confirmation',
        data: orderResp?.scratchCard,
      };
      yield put({type: SET_SCRATCH_CARD_MODAL, data: payload});
    }
    if (orderResp?.isMissionOxygenActive) {
      yield put({type: SET_CONFIRMATION_PAGE_MODAL, data: true});
    }
    if (!config.isApp) {
      // ==== Sending FB purchase event
      // ==== https://www.facebook.com/business/help/402791146561655?id=1205376682832142
      const trackedOrder = config.LSGetItem('trackedOrder') || {};
      if (!trackedOrder[data.orderNo]) {
        // debugger
        window?.fbq &&
        window.fbq('track', 'Purchase', {
          value: orderResp?.orderPayableAmount,
          currency: 'INR',
          orderNo: data.orderNo,
          purchaseValue: orderResp?.orderPayableAmount,
          customerId: config.getDeviceId(),
        });
        console.log('tracked using fb:');
        const gtmData = {
          eventName: 'checkoutSuccess',
          eventData: {
            category: 'checkout > successful_order',
            value: orderResp?.orderPayableAmount,
            label: data.orderNo,
          },
        };
        trackGtmEvent(gtmData);
        console.log('tracked using GTM:');
        const {ga} = config.ReactGA;
        // Sending ecommerce Analytics.js
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
        ga('require', 'ecommerce');
        const transactionProducts = [];
        cartItems.map(oi => {
          const item = {
            id: oi.sku,
            sku: oi.sku,
            name: oi.productName,
            price: oi.onSale ? oi.salePrice : oi.retailPrice,
            quantity: oi.inCartQty || oi.quantity,
            currency: 'INR',
          };
          transactionProducts.push(item);
          ga('ecommerce:addItem', item);
        });
        // debugger
        ga('ecommerce:addTransaction', {
          id: data.orderNo,
          revenue: orderResp?.orderPayableAmount,
          shipping:
          orderResp.orderDetails?.orderDetails?.priceDetails?.deliveryCharges,
          currency: 'INR',
        });
        ga('ecommerce:send');
        ga('ecommerce:clear');
        console.log('tracked using GA:');
        //==============   Standard Ecomm using universal analytics
        //==============   https://support.google.com/tagmanager/answer/6107169?authuser=5#standard-ecommerce
        // const ecomData = {
        //   transactionId: odResp.orderNo,
        //   transactionTotal: odResp.priceDetails?.orderPayableAmount,
        //   transactionShipping: odResp.priceDetails?.deliveryCharges,
        //   transactionProducts,
        //   event: 'Purchase',
        // };
        // // console.log('GTM_dataLayer: ', !!window?.dataLayer);
        // const dataLayer = window.dataLayer = window.dataLayer || [];
        // // console.log('EComm Data: ', ecommData);
        // dataLayer.push(ecomData);

        // ==============    Sending ecommerce with gtag.js
        // ==============    https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
        const gtagData = {
          transaction_id: data.orderNo,
          value: orderResp?.orderPayableAmount,
          currency: 'INR',
          tax: 0,
          shipping: orderResp.orderDetails?.priceDetails?.deliveryCharges,
          items: transactionProducts,
          event: 'purchase',
        };
        // debugger
        const noOp = () => {};
        const gtag = window.gtag || noOp;
        gtag('event', 'purchase', gtagData);
        const gtmDataLayer = window?.dataLayer || [];
        gtmDataLayer.push(gtagData);
        console.log('tracked using gtag.js:');

        // <!-- AdWords Event snippet for Purchase Website conversion page -->
        gtag('event', 'conversion', {
          send_to: '%REACT_APP_AD_WORDS_ID%/oYruCKDH7YwDENzH5KEC',
          value: orderResp?.orderPayableAmount,
          currency: 'INR',
          transaction_id: data.orderNo,
        });
        console.log('tracked purchase for adwords:');

        trackedOrder[data.orderNo] = new Date();
        config.LSSetItem('trackedOrder', trackedOrder);
      }
    }
    yield put({type: SET_ORDER_CONFIRMATION, data: orderResp});
    yield put({type: RESET_CART_SKUS});
    // if(launchInfo?.displayPrime && orderResp?.orderType !== 'POL') yield fork(getMembershipBannerDetailSaga);
  } catch (e) {
    console.log('Err @getOrderConfirmationSaga: ', e);
  }
}
export function* validateSelectedLocationForNudgeSaga({data, cb = null}) {
  console.warn('validateSelectedLocationForNudgeSaga', data);

  try {
    const {data: resp} = yield call(validateSelectedLocationForNudgeApi, data);
    console.warn('validateSelectedLocationForNudgeSaga', resp, data);

    if (resp?.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    if (cb) {
      cb(resp);
    }
  } catch (e) {
    console.log('Err @validateSelectedLocationForNudgeSaga in checkout: ', e);
    if (cb && !config.isApp) {
      cb({...e, error: true});
    }
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}
export function* removeShoppingCartSaga({data, cb = null}) {
  try {
    const {data: resp} = yield call(removeShoppingCartApi, data);
    const address = resp.customerAddress || resp.visitorAddress;
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...address};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (resp?.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    if (cb) {
      cb(resp);
    }
  } catch (e) {
    console.log('Err @removeShoppingCartSaga in checkout: ', e);
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}
