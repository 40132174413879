import config from 'appConfig';
import {call, put, all, select, fork} from 'redux-saga/effects';

import {
  SET_CHECKOUT_LOADING,
  SET_PAYMENT_DETAILS,
  SET_PG_CUSTOMER_ID,
  SET_PARTIAL_PAYMENT,
} from './checkout.action';

import {
  getPaymentSummaryApi,
  getPaymentMethodsApi,
  getPGCustomerIdApi,
  postPlaceOrderApi,
  postSaveUserPaymentApi,
  postSaveMultiModeUserPaymentApi,
  postVerifyPaymentApi,
  postVerifyPaymentApiPayU,
  verifyPayUVpaApi,
  postOrderInProgressApi,
} from './checkout.api';
import history from '../../history';
import { RESET_CART_SKUS } from '../../feature/cart/cart.action';
import { launchInfoSelector, SET_NOTIFICATION_MSG } from '../../initial.action';
import {
  CHECKOUT_FAILURE_REDIRECT,
  CHECKOUT_STEPS, KYC_STATUS,
  PAYMENT_GATEWAYS
} from '../../util/constantServices';
import RazorpayService from '../../util/RazorpayService';
import PayuService from '../../util/PayuService';
import {sendAnalytics} from 'hb-redux-store/analytics';
import { selectKycDetails } from '../kyc/kyc.selector';

const {trackGtmEvent = () => {}} = config;

export function* getPaymentSummarySaga({data = {}}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const {data: summary} = yield call(getPaymentSummaryApi, data);
    data.cb && data.cb();
    const {totalCreditLimit = 0, multiPayMode = false} = data;
    console.log('Summary: ', summary, data);
    yield put({type: SET_PAYMENT_DETAILS, data: {summary: {...summary, totalCreditLimit, multiPayMode}}});
  } catch (e) {
    console.log('Err@ getPaymentSummarySaga: ', e);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  }
}

export function* getPaymentDetailsSaga({data = {}}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    if (config.isApp) {
      yield put({type: SET_PARTIAL_PAYMENT, data: true});
    }
    const promises = [
      call(getPaymentSummaryApi, data),
      call(getPaymentMethodsApi, data),
    ];
    const [summaryResp, paymentMethodsResp] = yield all(promises);
    const summary = summaryResp.data;
    console.log('Details Api', summary);
    const paymentMethods = paymentMethodsResp.data;
    yield put({
      type: SET_PAYMENT_DETAILS,
      data: {summary, paymentMethods: paymentMethods.data},
    });
    const pgCid = paymentMethods.pgCid || null;
    const {pgId} = paymentMethods;
    let { savedUPIs = [] } = paymentMethods;
    const recommendedUpis = [], upis = [];
    savedUPIs.map(upi => {
      const vpa = { ...upi, tokenId: upi.tokenId || Math.random() };
      if (upi.isNumberVpa) recommendedUpis.push(vpa);
      else upis.push(vpa);
    });
    paymentMethods.savedUPIs = [...recommendedUpis, ...upis];
    const bnpl1Formated = [
      {
        status: true,
        minAmount: 0,
        name: 'LazyPay',
        code: 'LAZYPAY'
      }
    ];
    const bnpl2Original = [
      {
        Lazypay: {
          status: 1,
          kfsLink: 'https://www.somekfsLink.com', // only if applicable
          eligible: true,
          customerLinked: true,
          PayuToken: 'Token12345'
        },
        Simpl: {
          status: 1,
          availableBalance: 500, // only if applicable
          kfsLink: 'https://www.somekfsLink.com', // only if applicable
          eligible: true,
          customerLinked: true,
          PayuToken: '“Token78901',
          failure_code: 'E2408',
          failure_reason:
            'The transaction or loan amount is greater than the available credit line with the customer'
        }
      }
    ];
    const bnpl2Formatted = [
      {
        "status": false,
        "isLinked": false,
        "eligible": true,
        "name": "Simpl",
        "code": "SIMPL"
      },
      {
        "status": true,
        "availableBalance": 10.0,
        "isLinked": true,
        "eligible": true,
        "name": "LazyPay",
        "code": "LAZYPAY"
      },
      {
        "status": false,
        "isLinked": false,
        "eligible": false,
        "name": "MobiZip",
        "code": "MOBIZIP"
      }
    ];
    paymentMethods.bnpl = bnpl2Formatted;
    let multiPayMode = false;
    let totalCreditLimit = 0;
    let {payableAmount} = summary?.billingDetails;
    if (summary?.isPartialPayment && summary?.billingDetails.diffPartialPaymentAmount > 0) {
      payableAmount = summary?.billingDetails.diffPartialPaymentAmount;
    }
    if (payableAmount > 0) {
      let anyFullPaymentProvider = false;
      if (paymentMethods?.bnpl?.map) paymentMethods.bnpl.map(b => {
          anyFullPaymentProvider = anyFullPaymentProvider || (b?.availableBalance >= payableAmount);
          totalCreditLimit += (b?.availableBalance || 0);
        });
      if (totalCreditLimit < payableAmount) {
        multiPayMode = true;
      }
      console.log('payableAmount: ', payableAmount);
      console.log('totalCreditLimit: ', totalCreditLimit);
      console.log('multiPayMode: ', multiPayMode);
    }

    paymentMethods.multiPayMode = multiPayMode;
    summary.multiPayMode = multiPayMode;
    paymentMethods.totalCreditLimit = totalCreditLimit;
    summary.totalCreditLimit = totalCreditLimit;

    // if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
    //   savedUPIs = savedUPIs.map(upi => ({...upi, tokenId: Math.random()}));
    //   paymentMethods.savedUPIs = savedUPIs;
    // }
    // console.warn('getPaymentDetailsSaga', paymentMethods);

    yield put({
      type: SET_PAYMENT_DETAILS,
      data: {summary, paymentMethods, pgCid},
    });
    if (!pgCid && pgId === PAYMENT_GATEWAYS.RAZORPAY.id) {
      const pgCidResp = yield call(getPGCustomerIdSaga);
      console.log('GoT PGCID:', pgCidResp);
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  } catch (e) {
    console.log('Err@ getPaymentDetailsSaga: ', e);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  }
}

export function* getPGCustomerIdSaga() {
  try {
    const {data: resp} = yield call(getPGCustomerIdApi);
    yield put({type: SET_PG_CUSTOMER_ID, data: resp});
    return resp;
  } catch (e) {
    console.log('Err@ getPGCustomerIdSaga: ', e);
  }
}

export function* placeOrderSaga() {
  try {
    // return {data: {orderNo: 'BNPL_202409_' + Math.round(Math.random() * 1000)}};
    // const quantityFailure = {"action":"failure","data":{"status":"error","redirect":"cart","description":"Session item Quantity mismatch for cart item : EGG-jwlw"}};
    const data = yield call(postPlaceOrderApi);
    return data;
  } catch (e) {
    console.log('Err @placeOrderSage: ', e);
    return {data: {step: CHECKOUT_STEPS.PLACE_ORDER, error: e}};
  }
}
export function* validateKycSaga() {
  const { kycStatus } = yield select(selectKycDetails);
  return KYC_STATUS.COMPLETED === kycStatus?.status;
}

function* processBnplPayment({paymentResp, cardData}) {
  if (paymentResp?.status === 'success') {
    yield put({type: RESET_CART_SKUS});
    cardData.successCb && cardData.successCb({orderNo: paymentResp?.orderNo});
  } else if (paymentResp?.status === 'failure') {
    const failureUrl = `/co/payment?result=failed&reason=Fail%20to%20Pay%20Via%20BNPL.%20Please%20try%20another%20mode.`;
    history.replace(failureUrl);
    history.go();
  } else if (paymentResp?.status === 'pending') {
    PayuService.makeBnplPayment(paymentResp);
  }
}

export function* postPlaceOrderSaga({
  data: paymentPayload,
  razorPayInstance = null,
  payUInstance = null,
  cb,
  appFailedCb = null,
  payUSuccessCb = null,
  payUFailureCb = null,
}) {
  try {
    // const isKycCompleted = yield call(validateKycSaga);
    // if (!isKycCompleted) {
    //   throw { step: true, redirect: 'kyc' };
    // }
    if (!paymentPayload?.length) {
      throw 'Could not get payload. Please check your payment mode';
    }
    const cardData = paymentPayload[0];
    const multiPayMode =
      (paymentPayload.length > 1 && paymentPayload[1].multiPayMode) ||
      cardData.payVia.toLowerCase() === 'bnpl';
    const nonPOLMode = ['pod', 'cod', 'hbw'];
    if (
      !config.isApp &&
      cardData.pgId === PAYMENT_GATEWAYS.RAZORPAY.id &&
      !RazorpayService.getRazorPayInstance()
    ) {
      RazorpayService.initRazorpay();
    }
    const postDetails = {
      amount: cardData.amount,
      paymentMode: cardData.payVia,
      pgId: cardData.pgId,
      paymentMethod: 'POL',
      isPartialPayment: cardData?.isPartialPayment,
    };
    let label = [
      `pay_mode: ${cardData.mode}`,
      `transaction_amount: ${cardData.amount}`,
      `pg_id: ${cardData.pgId}`,
      `pay_via: ${cardData?.payVia || 'NA'}`,
    ];
    if (cardData?.payVia === 'CC') {
      postDetails.cardType = cardData?.cardType; // VISA/MC/RUPAY
      postDetails.last4 = cardData?.cardType;
      postDetails.saveVpa = !!cardData?.save;
      label.push(`extra: cardType-${cardData?.cardType}`);
    }
    if (cardData?.payVia === 'upi') {
      postDetails.vpa = cardData?.vpa;
      postDetails.saveVpa = !!cardData?.save;
      label.push(`extra: vpa-${cardData?.vpa}`);
    }
    if (cardData?.payVia === 'netbanking') {
      postDetails.bankCode = cardData?.bank;
      label.push(`extra: bank-${cardData?.bank}`);
    }
    if (cardData?.payVia === 'BNPL') {
      postDetails.bankCode = cardData?.bankcode;
      label.push(`extra: bank-${cardData?.bankcode}`);
    }
    if (cardData?.wallet) {
      postDetails.paymentWallet = cardData?.wallet;
      label.push(`extra: wallet-${cardData?.wallet}`);
    }
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      postDetails.paymentMode = cardData?.mode;
      postDetails.paymentMethod = cardData?.mode;
      if (cardData.mode === 'hbw') {
        label[1] = `transaction_amount: ${cardData?.differenceAmount}`;
        label.push(`extra: wallet_balance-${cardData?.hbWalletAmount}`);
      }
    }
    if (!config.isApp) {
      const gtmData = {
        eventName: 'addPaymentInfo',
        eventData: {
          category: 'checkout > placeorder',
          label: label.join(', '),
          value: cardData.amount,
        },
      };
      trackGtmEvent(gtmData);
    }
    const launchInfo = yield select(launchInfoSelector);
    const {data: resp} = yield call(placeOrderSaga);
    // console.log('place order saga: ', JSON.stringify(resp));
    if (resp.step) {
      if (cardData.failCb && !config.isApp) {
        return cardData.failCb(resp);
      } else if (appFailedCb) {
        return appFailedCb(resp);
      }
    }
    // debugger
    // console.warn('postPlaceOrderApi', resp, cardData);
    const apiPayload = {
      ...postDetails,
      orderNo: resp?.orderNo || '',
      membershipRefNo: resp?.membershipRefNo || '',
    }
    const paymentData = {
      paymentMode: cardData.mode,
      data: apiPayload,
    };
    // console.warn('paymentData', paymentData);
    let saveUserPaymentApi = postSaveUserPaymentApi;
    let saveUserPaymentPayload = paymentData;
    if (multiPayMode) {
      saveUserPaymentApi = postSaveMultiModeUserPaymentApi;
      saveUserPaymentPayload = {
        paymentMode: 'BNPL',
        data: [ paymentData.data ],
      };
      if (cardData.payVia?.toLowerCase() !== 'bnpl') {
        saveUserPaymentPayload.data.push({
          amount: cardData.amount,
          paymentMode: 'BNPL',
          pgId: cardData.pgId,
          paymentMethod: 'POL',
          isPartialPayment: cardData?.isPartialPayment,
          orderNo: resp?.orderNo || '',
          membershipRefNo: resp?.membershipRefNo || '',
        });
      }
    }
    const { data: paymentResp } = yield call(
      saveUserPaymentApi,
      saveUserPaymentPayload
    );
// {"paymentHash":"b6b8da5a6ae96681cbdc27aaa65d4b9e071cd9bc174dbfb6e86ea56c2b77ff39f4ef80e81283f566e21e7097c543c63f26a952823fad62ec343ea8ee2284aa8d","successURL":"https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=ZZ24120465QH&membershipRefNo=","failureURL":"https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=ZZ24120465QH&membershipRefNo=","cancelURL":"https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=ZZ24120465QH&membershipRefNo=","productInfo":"CHK-1006","hashFirstName":"PM VZSWQ","hashEmail":"8866255483@halalbox.in","amount":"10.00","udf1":"4123a1cb-5466-4605-a123-2a8a94800d9d","udf2":"","udf3":"","udf4":"","udf5":""},"partialPayment":false}
    debugger
    // return
    console.log(
      'save user payment: ',
      paymentData,
      JSON.stringify(paymentResp),
    );
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      yield put({type: RESET_CART_SKUS});
      if (!config.isApp) {
        cardData.successCb &&
          cardData.successCb({orderNo: resp?.orderNo});
      } else {
        cb({
          action: resp.status,
          data: {
            orderNo: resp.orderNo,
          },
        });
      }
    } else {
      if (!config.isApp) {
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          if (cardData?.payVia === 'BNPL' || paymentResp?.acsTemplate) {
            return yield processBnplPayment({paymentResp, cardData});
          }
          const transactionId = paymentResp.orderNo || resp.orderNo || resp.membershipRefNo;
          const payuData = {
            ...postDetails,
            ...cardData,
            firstname: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            txnid: transactionId,
            hash: paymentResp.payUHash.paymentHash,
            productinfo: paymentResp.payUHash.productInfo,
            surl: paymentResp.payUHash.successURL,
            curl: paymentResp.payUHash.cancelURL,
            furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: process.env.REACT_APP_PAYU_KEY || 'LlaSG8',
            payuUrl: process.env.REACT_APP_PAYU_POST_URL,
          };
          payuData.user_credentials = `${payuData.key}:${cardData.pgCid}`;
          PayuService.makePayment(payuData);
          return;
        }
        const rzrp = RazorpayService.getRazorPayInstance();
        if (!rzrp) {
          return cardData.failCb({
            step: CHECKOUT_STEPS.PG_NOT_LOADED,
            redirect: CHECKOUT_FAILURE_REDIRECT.PG_NOT_LOADED,
          });
        }
        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;
        delete rzrData.last4;
        delete rzrData.bin;
        delete rzrData.cardType;
        delete rzrData.isRechargeForOrder;
        delete rzrData.orderNo;
        delete rzrData.source;
        const razorPaySuccessCb = resp => {
          console.log('PaymentSuccess: ', resp);
          const verifyData = {
            status: 'success',
            orderNo: paymentData.orderNo,
            membershipRefNo: paymentData.membershipRefNo || '',
            success: {
              pgOId: resp.razorpay_order_id,
              pgPId: resp.razorpay_payment_id,
              pgSId: resp.razorpay_signature,
            },
          };
          postVerifyPaymentApi({orderNo: paymentData.orderNo, data: verifyData})
            .then(verifiedResp => {
              console.log('Payment verified', verifiedResp);
              if (!config.isApp) {
                cardData.successCb &&
                  cardData.successCb({orderNo: paymentData.orderNo});
              }
            })
            .catch(e => {
              console.log('Verification error: ', e);
              cardData.failCb &&
                cardData.failCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  error: {redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE},
                });
            });
        };
        const razorPayErrorCb = err => {
          console.log('RazorPay error: ', err);
          postVerifyPaymentApi({
            orderNo: paymentData.orderNo,
            data: {
              status: 'failure',
              ...err,
            },
          })
            .then(failedResp => {
              console.log('Failed payment reported. ', failedResp);
              if (!config.isApp) {
                cardData.failCb({
                  errorMessage:
                    err?.error?.description || failedResp.data?.description,
                });
              }
            })
            .catch(e => {
              console.log('Failure reporting error: ', e);
            });
        };
        const {deviceDetector = null} = config;
        console.log('deviceDetector: ', deviceDetector?.deviceDetect());
        console.log(
          'isIos device: ',
          deviceDetector?.isMacOs,
          deviceDetector?.isMobileSafari,
          deviceDetector?.isSafari,
          deviceDetector?.isIOS,
        );
        if (
          deviceDetector?.isMacOs ||
          deviceDetector?.isMobileSafari ||
          deviceDetector?.isSafari ||
          deviceDetector?.isIOS
        ) {
          console.log('Qualifying as apple device.');
          cardData.failCb({
            step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
            redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
            pgDetails: {
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            },
          });
        } else {
          const payNow = document.createElement('BUTTON');
          payNow.onclick = function() {
            console.log('Qualifying as non-apple device.');
            const razorPayPopup = RazorpayService.makePayment({
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            });
            setTimeout(() => {
              const hasPopup = !!razorPayPopup?._payment?.popup?.window
                ?.outerHeight;
              // debugger
              if (!hasPopup) {
                console.log('non-apple device popup failed');
                cardData.failCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
                  pgDetails: {
                    razorPayData: rzrData,
                    razorPaySuccessCb,
                    razorPayErrorCb,
                  },
                });
              }
            }, 250);
          };
          payNow.click();
        }
        // console.log('Razr data: ', rzrData);
        // rzrp.on('payment.success', razorpaySuccessCb);
        // rzrp.on('payment.error', razorpayErrorCb);
        // const razorPayPopup = rzrp.createPayment(rzrData);
        // setTimeout(() => {
        //   // TODO: Update logic to recognise if modal is opened or not.
        //   const hasPopup = !!razorPayPopup._payment?.popup?.window?.outerHeight;
        //   if (!hasPopup) {
        //     cardData.failCb({
        //       step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
        //       redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
        //       pgDetails: {
        //         rzrData,
        //         razorpaySuccessCb,
        //         razorpayErrorCb,
        //       }
        //     });
        //   }
        // }, 200);
      } else {
        if (cardData?.paymentMethod === 'POL') {
          delete cardData?.paymentMethod;
        }
        console.warn(
          'isPayu',
          cardData.pgId === PAYMENT_GATEWAYS.PAYU.id,
          payUInstance,
        );
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          const transactionNo = !!resp.orderNo ? resp?.orderNo : resp?.membershipRefNo;
          const payuData = {
            ...postDetails,
            ...cardData,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId:transactionNo,
            hash: paymentResp.payUHash.paymentHash,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: config.ENV.PG_PU_KEY,
            payment: paymentResp.payUHash.paymentHash,
            vas_for_mobile_sdk: paymentResp.payUHash.vasForMobileSDK,
            payment_related_details_for_mobile_sdk:
              paymentResp.payUHash.paymentRelatedDetailsForMobileSDK,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
          };
          payuData.userCredentials = `${payuData.key}:${cardData.pgCid}`;
          console.warn('paymentResp', paymentResp);
          console.warn('paymentObject', payuData);
          var paymentObject = {
            payUPaymentParams: payuData,
            payUCheckoutProConfig: {},
          };

          let finalPayUPayload = paymentObject;
          if (config?.getDeviceType() === 'iphone') {
            finalPayUPayload = payuData;
          }

          // Analytics
          const key = 'pg_payu_initialize';
          const payload = {
            ...postDetails,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId: transactionNo,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
            deviceId: config.getDeviceId(),
          };
          sendAnalytics({
            key,
            payload,
            firebasePriority: 1,
          })

          payUInstance.openCheckoutScreen(
            finalPayUPayload,
            (payUresponse, merchantResponse) => {
              // Analytics
              const key = 'pg_payu_error'
              const payload = {
                payUResponse: payUresponse,
                merchantResponse: merchantResponse || '',
                deviceId: config.getDeviceId(),
              };
              sendAnalytics({
                key,
                payload,
                firebasePriority: 1,
              })

              // error callback
              console.warn('PayU Error', payUresponse, '+', merchantResponse);
              const isObject = typeof(payUresponse)
              const verifyData = {
                status: 'failure',
                orderNo: resp.orderNo || '',
                membershipRefNo: resp.membershipRefNo || '',
                sdkCallBackStatus: 'failure',
                payUResponse: isObject === 'object' ? JSON.parse(payUresponse) : {},
                ...merchantResponse,
              };
              postVerifyPaymentApiPayU({
                data: verifyData,
              })
                .then(validateResponse => {
                  // payU error with validation error success
                  console.warn('payU error with validation API success');
                  // payUFailureCb(payUresponse, merchantResponse);
                  // Analytics
                  const key = 'pg_payu_error_validate_success'
                    const payload = {
                      response: JSON.stringify(validateResponse),
                      orderNo: resp.orderNo,
                      status: 'failure',
                      payUresponse: payUresponse || '',
                      merchantResponse: merchantResponse || '',
                      sdkCallBackStatus: 'failure',
                      deviceId: config.getDeviceId(),
                    };
                    sendAnalytics({
                      key,
                      payload,
                      firebasePriority: 1,
                    })

                  payUSuccessCb(
                    payUresponse,
                    merchantResponse,
                    validateResponse,
                  );
                })
                .catch(err => {
                  // payU error with validation failed
                  console.warn('payU error with validation failed', err);

                    // Analytics
                    const key = 'pg_payu_error_validate_error'
                    const payload = {
                      orderNo: resp.orderNo,
                      status: 'failure',
                      payUresponse: payUresponse || '',
                      merchantResponse: merchantResponse || '',
                      sdkCallBackStatus: 'failure',
                      description: err?.data?.description,
                      orderStatus: err?.data?.orderStatus,
                      deviceId: config.getDeviceId(),
                    };
                    sendAnalytics({
                      key,
                      payload,
                      firebasePriority: 1,
                    })

                  payUFailureCb(payUresponse, merchantResponse);
                });
            },
            (payUresponse, merchantResponse) => {
                // Analytics
                const key = 'pg_payu_success'
                const payload = {
                  req: finalPayUPayload,
                  res: {
                    ...payUresponse,
                    ...merchantResponse,
                  },
                  deviceId: config.getDeviceId(),
                };
                sendAnalytics({
                  key,
                  payload,
                  firebasePriority: 1,
                })


              //success callback
              const verifyData = {
                orderNo: resp.orderNo || '',
                membershipRefNo: resp.membershipRefNo || '',
                sdkCallBackStatus: 'success',
                status: 'success',
                payUResponse: JSON.parse(payUresponse),
                ...merchantResponse,
              };
              console.warn('PayU success', payUresponse, merchantResponse);
              postVerifyPaymentApiPayU({
                data: verifyData,
              })
                .then(validateResponse => {
                  // payU success with validation success
                  console.warn('payU success with validation success');
                    // Analytics
                    const key = 'pg_payu_success_validate_success'
                    const payload = {
                      response: JSON.stringify(validateResponse),
                      orderNo: resp?.orderNo,
                      status: 'success',
                      payUresponse: payUresponse || '',
                      merchantResponse: merchantResponse || '',
                      sdkCallBackStatus: 'success',
                      deviceId: config.getDeviceId(),
                    };
                    sendAnalytics({
                      key,
                      payload,
                      firebasePriority: 1,
                    })

                  payUSuccessCb(
                    payUresponse,
                    merchantResponse,
                    validateResponse,
                  );
                })
                .catch(err => {
                  // payU success with validation failed
                  console.warn('payU success with validation failed', err);
                  // Analytics
                  const key = 'pg_payu_success_validate_error'
                  const payload = {
                    orderNo: resp?.orderNo,
                    status: 'success',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'success',
                    description: err?.data?.description,
                    orderStatus: err?.data?.orderStatus,
                    deviceId: config.getDeviceId(),
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                  })

                  payUFailureCb(payUresponse, merchantResponse);
                });
            },
          );
          return;
        }

        // APP RAZORPAY

        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
          currency: 'INR',
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;

        console.warn('Payment by App', rzrData, config.ENV.PG_KEY);
        razorPayInstance
          .open(rzrData)
          .then(resp => {
            // handle success
            const verifyData = {
              status: 'success',
              orderNo: paymentData.orderNo,
              membershipRefNo: paymentData.membershipRefNo || '',
              success: {
                pgOId: resp?.razorpay_order_id,
                pgPId: resp?.razorpay_payment_id,
                pgSId: resp?.razorpay_signature,
              },
            };
            console.warn('Razorpay verifyData', verifyData);

            postVerifyPaymentApi({
              data: verifyData,
            })
              // Verify Payment Transaction
              .then(verifiedResp => {
                console.log('Payment verified', verifiedResp);
                if (config.isApp) {
                  // here fail
                  cb(verifiedResp);
                }
              })
              .catch(e => {
                console.log('Verification error: ', e);
                appFailedCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE,
                });
              });
          })
          .catch(err => {
            // handle failure
            console.warn('RazorPay error: ', err);
            let errorMsg = '';
            let statusCode = '';
            try {
              errorMsg = JSON.parse(err?.description || {})?.error;
              statusCode = JSON.parse(err?.description || {})?.http_status_code;
            } catch (e) {
              console.warn('Error Parsing Razorpay error', e);
              errorMsg = {
                error: err?.description,
              };
              statusCode = '';
            }
            postVerifyPaymentApi({
              orderNo: paymentData.orderNo,
              data: {
                status: 'failure',
                error: errorMsg,
                http_status_code: statusCode,
              },
            })
              .then(failedResp => {
                // const formattedError = (failedResp?.data?.description ) || (err?.description && JSON.parse(err?.description) && JSON.parse(err?.description)?.error?.description);
                console.warn(
                  'Failed payment reported. ',
                  failedResp,
                  JSON.parse(err?.description).description,
                );
                // appFailedCb(formattedError);

                appFailedCb(failedResp);
              })
              .catch(e => {
                console.warn('Failure reporting error: ', e);
                cb(err?.description);
              });
          });
      }
    }
  } catch (e) {
    console.log('Err @postPlaceOrderSaga: ', e);
    const cardData = paymentPayload.length && paymentPayload[0];
    cb && cb(e);
    cardData?.failCb && cardData.failCb(e);
    let errorMsg = 'Error while placing order. Please try again.';
    if (e.data?.description) {
      errorMsg = e.data?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* verifyVPASaga({data}) {
  try {
    const {pgId, vpa} = data;
    if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
      const [username, handle] = vpa.split('@');
      const {data: vpaResp} = yield call(verifyPayUVpaApi, {username, handle});
      data.successCb();
    } else {
      if (!config.isApp) {
        const rzrp = RazorpayService.getRazorPayInstance();
        rzrp
          .verifyVpa(data.vpa)
          .then(() => {
            data.successCb();
          })
          .catch(() => {
            data.failureCb();
          });
      } else {
        data.successCb();
      }
    }
  } catch (e) {
    console.log('Err @verifyVPA: ', e);
    data.failureCb();
  }
}

export function* postOrderInProgressSaga({ data }) {
  try {
    const { data: paymentResp } = yield call(postOrderInProgressApi, data);
    const launchInfo = yield select(launchInfoSelector);
    // [amount, payVia(upi, netbanking, BNPL, savedCard, savedUpi, wallet), pgId, isPartialPayment, wallet, bankcode, vpa, pgCid]
    const postDetails = {
      amount: paymentResp.amount,
      paymentMode: paymentResp.payVia,
      pgId: paymentResp.pgId,
      paymentMethod: 'POL',
      isPartialPayment: !!paymentResp?.isPartialPayment,
    };
    if (paymentResp?.payVia === 'upi') {
      postDetails.vpa = paymentResp?.vpa;
      postDetails.saveVpa = !!paymentResp?.save;
    }
    if (paymentResp?.payVia === 'netbanking') {
      postDetails.bankCode = paymentResp?.bank;
    }
    if (paymentResp?.payVia === 'BNPL' || paymentResp?.acsTemplate) {
      postDetails.bankCode = paymentResp?.bankcode;
      if (paymentResp?.acsTemplate) return PayuService.makeBnplPayment(paymentResp);
    }
    if (paymentResp?.wallet) {
      postDetails.paymentWallet = paymentResp?.wallet;
    }
    if (paymentResp.pgId === PAYMENT_GATEWAYS.PAYU.id) {
      const transactionId = paymentResp.orderNo;
      const payuData = {
        ...postDetails,
        firstname: paymentResp.payUHash.hashFirstName,
        email: paymentResp.payUHash.hashEmail,
        phone: launchInfo.mobile,
        txnid: transactionId,
        hash: paymentResp.payUHash.paymentHash,
        productinfo: paymentResp.payUHash.productInfo,
        surl: paymentResp.payUHash.successURL,
        curl: paymentResp.payUHash.cancelURL,
        furl: paymentResp.payUHash.failureURL,
        amount: paymentResp.payUHash.amount,
        udf1: paymentResp.payUHash.udf1,
        udf2: paymentResp.payUHash.udf2,
        udf3: paymentResp.payUHash.udf3,
        udf4: paymentResp.payUHash.udf4,
        udf5: paymentResp.payUHash.udf5,
        key: process.env.REACT_APP_PAYU_KEY || 'LlaSG8',
        payuUrl: process.env.REACT_APP_PAYU_POST_URL,
      };
      payuData.user_credentials = `${payuData.key}:${paymentResp.pgCid}`;
      PayuService.makePayment(payuData);
      return;
    }
    data.successCb && data.successCb(paymentResp);
  } catch (e) {
    console.log('Err @postOrderInProgress in checkout: ', e);
    data.failCb && data.failCb(e);
  }
}
