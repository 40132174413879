const prefix = 'CHECKOUT/';
export const GET_PAYMENT_DETAILS = `${prefix}GET_PAYMENT_DETAILS`;
export const SET_PAYMENT_DETAILS = `${prefix}SET_PAYMENT_DETAILS`;
export const GET_PAYMENT_SUMMARY = `${prefix}GET_PAYMENT_SUMMARY`;
export const GET_PG_CUSTOMER_ID = `${prefix}GET_PG_CUSTOMER_ID`;
export const SET_PG_CUSTOMER_ID = `${prefix}SET_PG_CUSTOMER_ID`;
export const GET_PLACE_ORDER = `${prefix}GET_PLACE_ORDER`;
export const SET_PLACE_ORDER = `${prefix}SET_PLACE_ORDER`;
export const GET_CREATE_ORDER = `${prefix}GET_CREATE_ORDER`;
export const SET_CREATE_ORDER = `${prefix}SET_CREATE_ORDER`;
export const POST_PG_PAYMENT = `${prefix}POST_PG_PAYMENT`;
export const SET_PG_PAYMENT = `${prefix}SET_PG_PAYMENT`;
export const SET_CHECKOUT_LOADING = `${prefix}SET_CHECKOUT_LOADING`;
export const POST_DELIVERY_ADDRESS = `${prefix}POST_DELIVERY_ADDRESS`;
export const POST_PLACE_ORDER = `${prefix}POST_PLACE_ORDER`;
export const GET_DELIVERY_SLOTS = `${prefix}GET_DELIVERY_SLOTS`;
export const SET_DELIVERY_SLOTS = `${prefix}SET_DELIVERY_SLOTS`;
export const POST_DELIVERY_SLOTS = `${prefix}POST_DELIVERY_SLOTS`;
export const ADD_NEW_DELIVERY_ADDRESS = `${prefix}ADD_NEW_DELIVERY_ADDRESS`;
export const UPDATE_DELIVERY_ADDRESS = `${prefix}UPDATE_DELIVERY_ADDRESS`;
export const VALIDATE_USER_LOCATION = `${prefix}VALIDATE_USER_LOCATION`;
export const GET_ORDER_CONFIRMATION = `${prefix}GET_ORDER_CONFIRMATION`;
export const SET_ORDER_CONFIRMATION = `${prefix}SET_ORDER_CONFIRMATION`;
export const VALIDATE_SELECTED_LOCATION_FOR_NUDGE = `${prefix}VALIDATE_SELECTED_LOCATION_FOR_NUDGE`;
export const REMOVE_SHOPPING_CART_NUDGE = `${prefix}REMOVE_SHOPPING_CART_NUDGE`;
export const POST_SUBSCRIPTION_USER = `${prefix}POST_SUBSCRIPTION_USER`;
export const SET_PARTIAL_PAYMENT = `${prefix}SET_PARTIAL_PAYMENT`;
export const RESET_ORDER_CONFIRMATION = `${prefix}RESET_ORDER_CONFIRMATION`;
export const VALIDATE_UPI = `${prefix}VALIDATE_UPI`;
export const SET_PRODUCTS_AND_DELIVERY_SLOT = `${prefix}SET_PRODUCTS_AND_DELIVERY_SLOT`;
export const POST_ORDER_IN_PROGRESS = `${prefix}POST_ORDER_IN_PROGRESS`;

export const RESET_SHIPMENT = `${prefix}RESET_SHIPMENT`;

export const subscribeNewUser = (data, cb) => ({ type: POST_SUBSCRIPTION_USER, data, cb });

export const getPaymentDetails = data => ({ type: GET_PAYMENT_DETAILS, data });

export const getPaymentSummary = data => ({ type: GET_PAYMENT_SUMMARY, data });

export const getPGCustomerId = () => ({ type: GET_PG_CUSTOMER_ID });

export const getPlaceOrder = () => ({ type: GET_PLACE_ORDER });

export const postPlaceOrder = (data, razorPayInstance, payUInstance, cb, appFailedCb, payUSuccessCb, payUFailureCb)  => ({ type: POST_PLACE_ORDER, data, razorPayInstance, payUInstance, cb, appFailedCb, payUSuccessCb, payUFailureCb });

export const getCreateOrder = data => ({ type: GET_CREATE_ORDER, data });

export const postPGPayment = data => ({ type: POST_PG_PAYMENT, data });

export const validateUserLocation = (data, cb) => ({ type: VALIDATE_USER_LOCATION, data, cb });

export const validateSelectedLocationForNudge = (data, cb) => ({ type: VALIDATE_SELECTED_LOCATION_FOR_NUDGE, data, cb });

export const removeShoppingCart = (data, cb) => ({ type: REMOVE_SHOPPING_CART_NUDGE, data, cb });

export const postDeliveryAddress = (data, redirectURL) => ({ type: POST_DELIVERY_ADDRESS, data, redirectURL});

export const addNewDeliveryAddress = (data, redirectURL) => ({ type: ADD_NEW_DELIVERY_ADDRESS, data, redirectURL});

export const updateDeliveryAddress = (data, cb) => ({ type: UPDATE_DELIVERY_ADDRESS, data, cb })

export const getDeliverySlots = () => ({ type: GET_DELIVERY_SLOTS });

export const postDeliverySlots = (data, redirect) => ({ type: POST_DELIVERY_SLOTS, data, redirect });

export const resetShipment = () => ({ type: RESET_SHIPMENT });

export const getOrderConfirmation = data => ({ type: GET_ORDER_CONFIRMATION, data });

export const validateUPI = data => ({ type: VALIDATE_UPI, data });

export const setIsPartialPayment = data => ({ type: SET_PARTIAL_PAYMENT, data });

export const resetOrderConfirmation = () => ({ type: RESET_ORDER_CONFIRMATION });

export const setProductsAndDeliverySlots = data => ({ type: SET_PRODUCTS_AND_DELIVERY_SLOT, data });

export const processOrderInProgress = data => ({ type: POST_ORDER_IN_PROGRESS, data });
