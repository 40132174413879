import { takeEvery } from 'redux-saga/effects';
import {
  GET_DELIVERY_SLOTS,
  GET_PAYMENT_DETAILS,
  GET_PG_CUSTOMER_ID,
  POST_DELIVERY_ADDRESS,
  ADD_NEW_DELIVERY_ADDRESS,
  POST_PLACE_ORDER,
  POST_DELIVERY_SLOTS,
  VALIDATE_USER_LOCATION,
  UPDATE_DELIVERY_ADDRESS,
  GET_ORDER_CONFIRMATION,
  VALIDATE_UPI,
  VALIDATE_SELECTED_LOCATION_FOR_NUDGE,
  REMOVE_SHOPPING_CART_NUDGE,
  POST_SUBSCRIPTION_USER,
  GET_PAYMENT_SUMMARY,
  POST_ORDER_IN_PROGRESS,
} from './checkout.action';
import {
  getPaymentDetailsSaga,
  getPGCustomerIdSaga,
  postPlaceOrderSaga,
  verifyVPASaga,
  getPaymentSummarySaga,
  postOrderInProgressSaga,
} from './checkout.saga';
import {
  addNewDeliveryAddressSaga,
  getDeliverySlotsSaga,
  getOrderConfirmationSaga,
  postDeliveryAddressSaga,
  postDeliverySlotsSaga,
  removeShoppingCartSaga,
  subscribeNewUserSaga,
  updateDeliveryAddressSaga,
  validateSelectedLocationForNudgeSaga,
  validateUserLocationSaga
} from './addressAndDeliverySlot.saga';

export const paymentSaga = [
  takeEvery(POST_SUBSCRIPTION_USER, subscribeNewUserSaga),
  takeEvery(GET_PAYMENT_DETAILS, getPaymentDetailsSaga),
  takeEvery(GET_PG_CUSTOMER_ID, getPGCustomerIdSaga),
  takeEvery(POST_DELIVERY_ADDRESS, postDeliveryAddressSaga),
  takeEvery(ADD_NEW_DELIVERY_ADDRESS, addNewDeliveryAddressSaga),
  takeEvery(GET_DELIVERY_SLOTS, getDeliverySlotsSaga),
  takeEvery(POST_PLACE_ORDER, postPlaceOrderSaga),
  takeEvery(POST_DELIVERY_SLOTS, postDeliverySlotsSaga),
  takeEvery(VALIDATE_USER_LOCATION, validateUserLocationSaga),
  takeEvery(UPDATE_DELIVERY_ADDRESS, updateDeliveryAddressSaga),
  takeEvery(GET_ORDER_CONFIRMATION, getOrderConfirmationSaga),
  takeEvery(VALIDATE_UPI, verifyVPASaga),
  takeEvery(
    VALIDATE_SELECTED_LOCATION_FOR_NUDGE,
    validateSelectedLocationForNudgeSaga
  ),
  takeEvery(REMOVE_SHOPPING_CART_NUDGE, removeShoppingCartSaga),
  takeEvery(GET_PAYMENT_SUMMARY, getPaymentSummarySaga),
  takeEvery(POST_ORDER_IN_PROGRESS, postOrderInProgressSaga),
];
