import config from 'appConfig';
import {call, put, select, fork} from 'redux-saga/effects';
import {
  RESET_LOGIN,
  SET_ERROR_MSG,
  SET_LOADING,
  SET_LOGOUT_INFO,
  SET_USER,
  SET_USER_EMAIL,
  SET_VISIBLE_BLOCK_FOR_LOGIN,
  SET_CCA_LOGIN_MSG,
  SET_LOGIN_DISABLED,
  ACTIVE_LOGIN_DISABLED
} from 'hb-redux-store/feature/login/login.action';
import {
  postVerifyOTPApi,
  postPhoneNoForOTPApi,
  postEmailLoginApi,
  postLogoutApi,
  postForgotPasswordApi,
  postResetPasswordApi,
  getUserEmailApi,
} from 'hb-redux-store/feature/login/login.api';
import {
  RESET_CART_SKUS,
  SET_CART_QTY_COUNT,
} from 'hb-redux-store/feature/cart/cart.action';
import {
  SET_LAUNCH_INFO,
  launchInfoSelector,
} from 'hb-redux-store/initial.action';
import history from 'hb-redux-store/history';
import {SET_NOTIFICATION_MSG} from 'hb-redux-store/initial.action';
import {sendAnalytics} from 'hb-redux-store/analytics';
import {
  SET_MERGE_CART_ON_LOGIN_MODAL,
  SET_LOGIN_MODAL,
} from '../modal/modal.action';
import {postUserLocationSaga} from '../home/home.saga';
import {postVerifyCCAForOfcApi} from './login.api';
import {showCartSaga} from '../cart/cart.saga';
import {postMergeOrFlushCartApi} from '../cart/cart.api';
import {getSubscriptionCalendarSaga} from '../subscription/subscription.saga';
import {getNotificationsSaga} from '../notifications/notifications.saga';
import { getMembershipBannerDetailSaga } from 'hb-redux-store/feature/halalboxPrime/hbPrime.saga'
import { SET_KYC_STATUS } from '../kyc/kyc.action';
import { KYC_STATUS } from '../../util/constantServices';

export function* postLogoutSaga({cb = {}}) {
  try {
    const launchInfoOld = yield select(launchInfoSelector);
    const launchInfo = {...launchInfoOld};
    yield call(postLogoutApi);
    yield put({type: SET_LOGOUT_INFO});
    const launchData = {};
    if (launchInfoOld.isSubscriptionActive) {
      launchData.isSubscriptionActive = true;
    }
    yield put({type: SET_LAUNCH_INFO, data: launchData});
    yield put({type: RESET_CART_SKUS});
    yield put({ type: SET_KYC_STATUS, data: null });
    delete launchInfo.customerFullName;
    delete launchInfo.mobile;
    delete launchInfo.email;
    delete launchInfo.referralActive;
    delete launchInfo.persistentTicket;
    delete launchInfo.loggedIn;
    delete launchInfo.showMergeCartDialog;
    delete launchInfo.addressId;
    // yield put({ type: POST_USER_LOCATION, data: { ...launchInfo } });
    yield call(postUserLocationSaga, {data: launchInfo});
    // if(launchInfo?.displayPrime) yield fork(getMembershipBannerDetailSaga);
    if (!config.isApp) {
      history.push('/');
    } else {
      cb();
    }
    // resetDeviceId();
  } catch (e) {
    if (cb) {
      cb();
    }
    console.log('Err@ getLogoutSaga: ', e);
  }
}

export function* postForgotPasswordSaga({data}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: resp} = yield call(postForgotPasswordApi, data);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Reset Link sent to Email',
      },
    });
    yield put({type: SET_LOADING, data: false});
  } catch (e) {
    console.log('Err@ postForgotPasswordSaga: ', e);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: e?.data?.description || 'Error sending reset link to Email',
      },
    });
    yield put({type: SET_LOADING, data: false});
  }
}

export function* postPhoneNoForOTPSaga({data, cb = {}}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: resp} = yield call(postPhoneNoForOTPApi, data);
    yield put({
      type: SET_VISIBLE_BLOCK_FOR_LOGIN,
      data: {resendWaitTimer: resp.timer, visibleBlock: 'OTP'},
    });
    yield put({type: SET_LOADING, data: false});
    if (config.isApp) {
      cb('OTP sent succesfully');
    }
  } catch (e) {
    console.log('Err@ postPhoneNoForOTPSaga: ', e);
    if (e.data && (e.data.message || e?.data?.description)) {
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: e?.data?.message || e?.data?.description
        }
      });
    }
    yield put({type: SET_LOADING, data: false});
  }
}

export function* postVerifyOTPSaga({data, cb}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const payload = config?.isApp ? data?.api : data;
    const {data: loginResp} = yield call(postVerifyOTPApi, payload);
    yield put({type: SET_USER, data: loginResp});
    yield fork(getNotificationsSaga, {});
    if (!config.isApp) {
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...loginResp,
        ...loginResp.customerAddress,
      };
      // if(launchData?.displayPrime) yield fork(getMembershipBannerDetailSaga);
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      const kycStatus = { status: launchData?.kycStatus, entityType: launchData?.entityType };
      yield put({ type: SET_KYC_STATUS, data: kycStatus });
      if (config?.clevertap) {
        const clevertapData = { Identity: config.getDeviceId() };
        if (launchData.customerFullName) clevertapData.Name = launchData.customerFullName;
        if (launchData.email) clevertapData.Email = launchData.email;
        if (launchData.mobile) clevertapData.Phone = `+91${launchData.mobile}`;
        const clevertapPayload = {...clevertapData, ...launchData}
        config.clevertap.sendCleverTapUserDetails({ Site: clevertapPayload });
      }
      let redirectUrl = data.redirectUrl || '/';
      // if (kycStatus.status !== KYC_STATUS.COMPLETED) redirectUrl = '/my/kyc';
      if (loginResp.totalCartQuantity) {
        yield put({
          type: SET_CART_QTY_COUNT,
          data: loginResp.totalCartQuantity,
        });
      }
      const isSubscription = redirectUrl.indexOf('subscription') > -1;
      if (loginResp.showMergeCartDialog) {
        if (isSubscription) {
          const resp = yield call(postMergeOrFlushCartApi, {mergeCart: true});
          if (resp?.data?.totalCartQuantity) {
            yield put({
              type: SET_CART_QTY_COUNT,
              data: resp?.data?.totalCartQuantity,
            });
          }
        } else {
          yield put({
            type: SET_MERGE_CART_ON_LOGIN_MODAL,
            data: {
              mergeCartOnLoginModal: true,
              mergeCartSource: data.redirectUrl ? 'CART' : 'HOME',
            },
          });
        }
      }
      if (isSubscription && config?.deviceDetection?.isBrowser) {
        yield call(getSubscriptionCalendarSaga);
      }
      if (redirectUrl === '/cart' && config?.deviceDetection?.isBrowser) {
        yield call(showCartSaga);
      }
      if (loginResp?.isPrimeMember) {
        history.replace('/');
      } else history.replace(redirectUrl);
    } else if (cb) {
      // App

      // Update Clevertap Profile
      if (loginResp) {
        const payload = {
          ...loginResp,
          ...loginResp.customerAddress,
          Name: loginResp?.customerFullName,
          Identity: config.getDeviceId(),
          Email: loginResp?.email,
          Phone: `+91${loginResp?.phoneNo}`,
          Location: loginResp?.customerAddress?.adcCode,
        };

        sendAnalytics({
          payload,
          onUserLogin: true,
          clevertapPriority: 1,
        });
      }
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...loginResp,
        ...loginResp.customerAddress,
        mobile: loginResp.phoneNo,
      };
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      const kycStatus = { status: launchData?.kycStatus, entityType: launchData?.entityType };
      yield put({ type: SET_KYC_STATUS, data: kycStatus });
      if (loginResp.totalCartQuantity) {
        yield put({
          type: SET_CART_QTY_COUNT,
          data: loginResp.totalCartQuantity,
        });
      }
      if (data?.source === 'RamzaanSpecial' && loginResp.showMergeCartDialog) {
        const resp = yield call(postMergeOrFlushCartApi, {mergeCart: true});
        if (resp?.data?.totalCartQuantity) {
          yield put({
            type: SET_CART_QTY_COUNT,
            data: resp?.data?.totalCartQuantity,
          });
        }
      } else {
        if (loginResp.showMergeCartDialog) {
          yield put({
            type: SET_MERGE_CART_ON_LOGIN_MODAL,
            data: {mergeCartOnLoginModal: true, mergeCartSource: 'CART'},
          });
        }
      }
      cb(loginResp);
    }
    // if (history.location.pathname === '/cart') {
    //   yield call(showCartSaga);
    // }
    yield put({type: RESET_LOGIN});
    yield put({type: SET_LOGIN_MODAL, data: false});
  } catch (e) {
    yield put({type: SET_LOGIN_DISABLED, data: e?.data});
    if (e?.data?.loginDisable) {
      yield put({type: ACTIVE_LOGIN_DISABLED, data: data?.activeLoginDisabledTimer});
    }
    console.log('Err@ postOTPToVerifySaga: ', e);
    if (e.data && e.data.description) {
      yield put({type: SET_ERROR_MSG, data: e.data.description});
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: e?.data?.description,
        },
      });
    }
    yield put({type: SET_LOADING, data: false});
  }
}

export function* postEmailLoginSaga({data, cb}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: resp} = yield call(postEmailLoginApi, data);
    yield put({type: SET_USER, data: resp});
    yield call(getNotificationsSaga, {});
    if (!config.isApp) {
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...resp,
        ...resp.customerAddress,
        mobile: resp.phoneNo,
      };
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      const redirectUrl = data.redirectUrl || '/';
      if (resp.showMergeCartDialog) {
        yield put({
          type: SET_MERGE_CART_ON_LOGIN_MODAL,
          data: {
            mergeCartOnLoginModal: true,
            mergeCartSource: data.redirectUrl ? 'CART' : 'HOME',
          },
        });
      }
      if (resp.totalCartQuantity) {
        yield put({type: SET_CART_QTY_COUNT, data: resp.totalCartQuantity});
      }
      history.replace(redirectUrl);
    } else {
      // App
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...resp,
        ...resp.customerAddress,
        mobile: resp.phoneNo,
      };
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      if (resp.totalCartQuantity) {
        yield put({type: SET_CART_QTY_COUNT, data: resp.totalCartQuantity});
      }
      if (data?.source === 'RamzaanSpecial' && resp.showMergeCartDialog) {
        const resp = yield call(postMergeOrFlushCartApi, {mergeCart: true});
        if (resp?.data?.totalCartQuantity) {
          yield put({
            type: SET_CART_QTY_COUNT,
            data: resp?.data?.totalCartQuantity,
          });
        }
      } else {
        if (resp.showMergeCartDialog) {
          yield put({
            type: SET_MERGE_CART_ON_LOGIN_MODAL,
            data: {mergeCartOnLoginModal: true, mergeCartSource: 'CART'},
          });
        }
      }
      cb(resp);
    }
    if (!config.isApp && history.location.pathname === '/cart') {
      yield call(showCartSaga);
    }

    yield put({type: SET_LOADING, data: false});
    yield put({type: SET_LOGIN_MODAL, data: false});
  } catch (e) {
    console.log('Err@ postEmailLoginSaga: ', e);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: e?.data?.description,
      },
    });
    if (e.data && e.data.message) {
      yield put({type: SET_ERROR_MSG, data: e.data && e.data.message});
    }
    yield put({type: SET_LOADING, data: false});
  }
}

export function* postResetPasswordSaga({data, cb}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: resp} = yield call(postResetPasswordApi, data);
    yield put({type: SET_USER, data: resp});
    if (!config.isApp) {
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...resp,
        ...resp.customerAddress,
        mobile: resp.phoneNo,
      };
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      const redirectUrl = data.redirectUrl || '/';
      if (resp.showMergeCartDialog) {
        yield put({
          type: SET_MERGE_CART_ON_LOGIN_MODAL,
          data: {
            mergeCartOnLoginModal: true,
            mergeCartSource: data.redirectUrl ? 'CART' : 'HOME',
          },
        });
      }
      if (resp.totalCartQuantity) {
        yield put({type: SET_CART_QTY_COUNT, data: resp.totalCartQuantity});
      }
      history.replace(redirectUrl);
    } else {
      // App
      yield put({type: RESET_LOGIN});
      const launchData = {
        ...resp,
        ...resp.customerAddress,
        mobile: resp.phoneNo,
      };
      delete launchData.customerAddress;
      yield put({type: SET_LAUNCH_INFO, data: launchData});
      if (resp.showMergeCartDialog) {
        yield put({
          type: SET_MERGE_CART_ON_LOGIN_MODAL,
          data: {mergeCartOnLoginModal: true, mergeCartSource: 'CART'},
        });
      }
      if (resp.totalCartQuantity) {
        yield put({type: SET_CART_QTY_COUNT, data: resp.totalCartQuantity});
      }
      cb && cb(resp);
    }
    yield put({type: SET_LOADING, data: false});
  } catch (e) {
    console.log('Err@ postResetPasswordSaga: ', e);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: e?.data?.description,
      },
    });
    if (e.data && e.data.message) {
      yield put({type: SET_ERROR_MSG, data: e.data && e.data.message});
    }
    yield put({type: SET_LOADING, data: false});
  }
}

export function* getUserEmailSaga({data}) {
  try {
    yield put({type: SET_LOADING, data: false});
    const {data: resp} = yield call(getUserEmailApi, data);
    yield put({type: SET_USER_EMAIL, data: resp});
  } catch (e) {
    console.log('Err@ getUserEmailSaga: ', e);
    yield put({type: SET_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Error getting email to reset password.',
      },
    });
  }
}

export function* postVerifyCCAForOfcSaga({data}) {
  try {
    yield call(postVerifyCCAForOfcApi, data);
    const windowRef = window || {};
    windowRef.location.replace('/');
  } catch (e) {
    console.log('Err @postVerifyCCAForOfcSaga: ', e);
    const message = 'Request processing failed. Please try again.';

    yield put({type: SET_CCA_LOGIN_MSG, data: message});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message,
      },
    });
  }
}
