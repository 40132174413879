import request from 'hb-redux-store/util/request';

export const getPaymentSummaryApi = ({isPartialPayment=true}) =>
  request.get(`/api/payment/v3/details?isPartialPayment=${isPartialPayment}`);

export const getPaymentMethodsApi = ({ halalboxMoney = false }) =>
  request.get(`/api/payment/v3/methods?halalboxMoney=${halalboxMoney}`);

export const getPGCustomerIdApi = () => request.post('/api/payment/customer');

export const postDeliveryAddressApi = data =>
  request.post('/api/updateDeliveryAddress', data);

export const addNewDeliveryAddressApi = data =>
  request.post('/api/addNewDeliveryAddress', data);

export const updateDeliveryAddressApi = data =>
  request.post('/api/updateDeliveryAddress', data);

export const subscribeNewUserApi = data =>
  request.post('/api/customerSubscription', data);

export const validateUserLocationApi = data =>
  request.post('/api/validateSelectedLocation', data);

export const validateSelectedLocationForNudgeApi = data =>
  request.post('/api/validateSelectedLocationOnAddressScreen', data);

export const removeShoppingCartApi = data =>
  request.post('/api/deleteExistingShoppingCart', data);

export const getDeliverySlotsApi = () => {
  return request.post('/api/getDeliverySlots');
};

export const postDeliverySlotsApi = data =>
  request.post('/api/updateCartShipment', data);

export const postPlaceOrderApi = () => request.post(`/api/v3/orders/new`);

export const postSaveUserPaymentApi = ({ paymentMode, orderNo, data }) =>
  request.post(`/api/orders/payment-methods/V4/${paymentMode}`, data); // COD or POL

export const postSaveBnplPaymentApi = ({ paymentMode, orderNo, data }) =>
  request.post(`/api/orders/payment-methods/V5/${paymentMode}`, data); // COD or POL

export const postSaveMultiModeUserPaymentApi = ({ paymentMode, data }) =>
  request.post(`/api/orders/payment-methods/V6/${paymentMode}`, data); // COD or POL

export const postVerifyPaymentApi = ({ data }) =>
  request.post(`/api/orders/v2/validate-payment`, data);

export const postVerifyPaymentApiPayU = ({ data }) =>
request.post(`/api/orders/v2/validate-payment-payu-app`, data);

export const getOrderConfirmationApi = ({ orderNo }) => {
  return request.get(`/api/v2/order-confirmation/${orderNo}`);
};

export const verifyPayUVpaApi = data =>
  request.post('/api/payment/validateVpa', data);

export const postOrderInProgressApi = ({tnxId}) => {
  const resp = {
    "action": "success",
    "data": {
    "status": "success",
      "orderNo": "RE241204BUTQ",
      "pgCustomerId": "cust_FssluI2vP2mmfN",
      "pgOrderId": "75736",
      "orderStatus": "ORDER_CREATED",
      "payUHash": {
      "paymentHash": "f4887886a5c6af4257001ae9640742ac941a45851a384fc285d9d27e166e2bf86c27dcf8158e298e5ec78f4362385dcfddd4bdeeb9d6cbf716e2b1890c10c456",
        "successURL": "https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=RE241204BUTQ&membershipRefNo=",
        "failureURL": "https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=RE241204BUTQ&membershipRefNo=",
        "cancelURL": "https://b2b.myhalalbox.in/api/orders/v3/validate-payment-web?vid=4db392ec-e466-4b57-960c-6d2e010cefcb&orderNo=RE241204BUTQ&membershipRefNo=",
        "productInfo": "CHK-1006",
        "hashFirstName": "PM VZSWQ",
        "hashEmail": "8866255483@halalbox.in",
        "amount": "179.00",
        "udf1": "f2d88618-915d-44bd-82bd-206e9cb3667a",
        "udf2": "",
        "udf3": "",
        "udf4": "",
        "udf5": ""
    },
    "paymentMode": "SAVEDUPI",
      "bankCode": "SAVEDUPI",
      "vpa": "anything@payu"
  }
  };
  if (tnxId === 'RE241204BUTQ') return Promise.resolve(resp);
  return request.post(`/api/transaction-in-progress/${tnxId}`);
};
